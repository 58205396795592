import {
  extendTheme,
  theme as base,
  withDefaultColorScheme,
  withDefaultVariant,
  
} from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";
const inputSelectStyles = {
  variants: {
    filled: {
      field: {
        bg: "gray.50",
        my: 1,
        borderColor: "gray.200",
        color: "gray.600",
        _placeholder: { opacity: 0.4, color: "inherit" },
        _focus: {
          borderColor: "brand.500",
          bg: "white",
        },
        _hover: {
          borderColor: "brand.500",
          bg: "gray.50",
        },
      },
    },
    outline: {
      field: {
        bg: "transparent",
        my: 1,
        borderColor: "gray.200",
        color: "gray.600",
        _placeholder: { opacity: 0.4, color: "inherit" },
        _focus: {
          borderColor: "brand.500",
          bg: "transparent",
        },
        _hover: {
          borderColor: "brand.500",
          bg: "gray.50",
        },
      },
    },
  },
  sizes: {
    md: {
      field: {
        borderRadius: "6px",
        height: "46px",
      },
    },
  },
};

// const iconButtonStyles = {
//
// }

const buttonStyles = {
  baseStyle: {
    fontWeight: 500,
    borderRadius: "6px", // <-- border radius is same for all variants and sizes
  },
  // Two sizes: sm and md
  sizes: {
    sm: {
      fontSize: "sm",
      px: 4, // <-- px is short for paddingLeft and paddingRight
      py: 3, // <-- py is short for paddingTop and paddingBottom
    },
    md: {
      fontSize: "md",
      px: 6, // <-- these values are tokens from the design system
      py: 4, // <-- these values are tokens from the design system
    },
  },
  // Two variants: outline and solid
  variants: {
    neutral: {
      color: "gray.600",
      bg: "white",
      border: "1px solid",
      borderColor: "gray.200",
    },
    grey: {
      color: "brand.500",
      bg: "gray.100",
    },
    outline2: {
      border: "1px solid",
      borderColor: "brand.500",
      color: "brand.500",
      _hover: {
        color: "white",
        bg: "brand.500",
      },
    },
    iconButton: {
      color: "brand.500",
      bg: "#00B2951A",
      _hover: {
        color: "white",
        bg: "brand.500",
      },
    },
    pageButton: {
      color: "white",
      bg: "brand.500",
      _hover: {
        color: "white",
        bg: "brand.800",
      },
      _disabled: {
        color: "brand.800",
        bg: "gray.100",
        _hover: {
          color: "brand.800",
          bg: "gray.100",
        },
      },
    },
    solid: {
      bg: "brand.500",
      color: "white",
    },
    red: {
      bg: "red.500",
      color: "white",
    },
  },
  // The default size and variant values
  defaultProps: {
    size: "md",
    variant: "solid",
  },
};

const breakpoints = {
  xs: "320px",
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1024px",
  "2xl": "1200px",
  "3xl": "1440px",
  "4xl": "1920px",
  "5xl": "2240px",
  "6xl": "2560px",
};

/* const breakpoints = {
  md: "550px",
  lg: "1200px",
  xl: "1500px",
  "2xl": "2000px",
}; */

const theme = extendTheme(
  {
    styles: {
      global: (props) => ({
        body: {
          bg: mode("#fefefe", "#021b17")(props),
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          scrollBehavior: "smooth",
          boxSizing: "border-box",
          width: "100vw",
          height: "100vh",
          maxWidth: "100vw",
          padding: 0,
          margin: 0,
          color: "gray.800",
          overflowX: "hidden",
          fontFamily: "Poppins, sans-serif",
        },
        ".shepherd-content":{
          backgroundColor: mode("#fefefe", "#021b17")(props),
          color: mode("black", "white")(props)
        },
        ".shepherd-text":{
          color: mode("black", "white")(props)
        },
        ".shepherd-has-title .shepherd-content .shepherd-header":{
          backgroundColor: mode("#fefefe", "#021b17")(props),
          color: mode("black", "white")(props),
        },
        ".shepherd-title":{
          color: mode("black", "brand.500")(props)
        },
        ".shepherd-target":{
          color: mode("inherit", "white")(props),
          background: mode("white", "brand.500")(props),
          borderColor:mode("white", "brand.500")(props),
          borderStyle:"solid",
          borderWeight:"14px"
        },
        ".shepherd-target p":{
          color: mode("brand.500", "white")(props),
           },
       
      }),
    },
    breakpoints: { ...breakpoints },
    colors: {
      copyright: "#A4A4A4",
      news_bg: "rgba(0, 178, 149, 0.1)",
      white: "#FEFEFE",
      "dashboard-content": "#f9fafa",
      facebook: "#3B5998",
      hamburger: "#00B295",
      black: "#000806",
      brand: {
        10: "rgba(0, 178, 149, 0.1)",
        40: "#b9d1cd",
        50: "#80d9ca",
        100: "#66d1bf",
        200: "#4dc9b5",
        300: "#33c1aa",
        400: "#1abaa0",
        500: "#00a086",
        600: "#00b295",
        700: "#008e77",
        800: "#007d68",
        900: "#006b59",
      },
      danger: "#FF302F",
      table_striped: "#00B2950F",
      red2: {
        50: "rgba(191,64,64,0.73)",
        100: "rgba(186,51,51,0.68)",
        200: "rgba(182,38,38,0.57)",
        300: "rgba(178,25,25,0.53)",
        400: "rgba(173,13,13,0.47)",
        500: "rgba(169,0,0,0.51)",
        600: "rgba(161,0,0,0.47)",
        700: "rgba(152,0,0,0.35)",
        800: "#900000",
        900: "#870000",
      },
    },
    fonts: {
      heading: `Poppins, ${base.fonts?.heading}`,
      body: `Poppins, ${base.fonts?.body}`,
    },
    components: {
      Input: { ...inputSelectStyles },
      NumberInput: { ...inputSelectStyles },
      Select: { ...inputSelectStyles },
      Button: { ...buttonStyles },
      Modal: {
        baseStyle: (props) => ({
          dialog: {
            bg: props.colorMode === 'dark' ? '#021b17' : 'white', 
          },
        }),
      },
      Menu: {
        baseStyle: (props) => ({
          list: {
            bg: props.colorMode === 'dark' ? '#021b17' : 'white', 
          },
        }),
      },
      Popover: {
        baseStyle: (props) => ({
          content: {
            bg: props.colorMode === 'dark' ? '#021b17' : 'white', 
          },
        }),
      },
      Select: {
        baseStyle: (props) => ({
          bg: props.colorMode === 'dark' ? '#021b17' : 'white', 
        }),
      },
    },
    initialColorMode: "light",
    useSystemColorMode: false,
  },
  withDefaultColorScheme({
    colorScheme: "gray",
    components: ["Checkbox"],
  }),
  withDefaultVariant({
    variant: "filled",
    components: ["Input", "Select"],
  })
);

export default theme;
