
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import React from "react";
import "@fontsource/poppins";
import { ChakraProvider } from "@chakra-ui/react";
import "@/styles/globals.css";
import "@/styles/dashboard.css";
import theme from "@/theme/index";
import Head from "next/head";
import { AuthProvider } from "@/src/contexts/AuthContext";
import NextNProgress from 'nextjs-progressbar';
import "video.js/dist/video-js.css";
import "videojs-dock/dist/videojs-dock.js";
import "videojs-dock/dist/videojs-dock.css";
import "focus-visible/dist/focus-visible";
import "@/styles/customTheme.scss";
import "@/styles/video.css";
import "@/styles/tour.css";
import "@/styles/video-player.css";
import "@/styles/carousel.css";
import Script from "next/script";

function MyApp({ Component, pageProps }) {
  return (
    <ChakraProvider theme={theme}>
      <Script
        async
        id="my-analytic1"
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
      />

      <Script id="my-analytic2" strategy="lazyOnload">
        {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
                    page_path: window.location.pathname,
                    });
                `}
      </Script>
      <Head>
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />
        <meta content="IE=edge" httpEquiv="X-UA-Compatible" />
        <meta name="description" content={siteDescription} />
        <meta name="keywords" content={siteKeywords} />
        <meta
          property="og:title"
          content="Watchman - vulnerabilities monitoring."
        />
        <meta property="og:image" content="https://watchman.bj/og.png" />
        <meta property="og:description" content={siteDescription} />
        <meta property="og:type" content="website" />
        <meta
          property="og:site_name"
          content="Watchman - vulnerabilities monitoring."
        />
        <meta property="og:url" content="https://watchman.bj/" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@watchmanapp" />
        <meta name="twitter:creator" content="@watchmanapp" />
        <meta name="twitter:description" content={siteDescription} />
        <meta name="twitter:image" content="https://watchman.bj/og.png" />

        <link rel="icon" sizes="180x180" href="/icons/apple-touch-icon.png" />
        <link rel="icon" sizes="32x32" href="/icons/favicon_x32.png" />
        <link rel="icon" sizes="16x16" href="/icons/favicon_x16.png" />
        <title>Watchman</title>
      </Head>
      <AuthProvider>
        <NextNProgress
          color="#00a086"
          startPosition={0.3}
          stopDelayMs={200}
          height={3}
        />
        <Component {...pageProps} />
      </AuthProvider>
    </ChakraProvider>
  );
}

const __Page_Next_Translate__ = MyApp;

export const siteKeywords =
  "gits, vulnerability, exploits, patching, monitor, stack, information, systems, global, it, service, app, watchman, alerts, management, track, monitoring";
export const siteDescription =
  "Watchman is a tool made simple, which help you to keep yours eyes into your information system.";


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  